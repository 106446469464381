<template>
  <div>
    <MobileHeader v-if="isMobile"/>
    <BeeHeader v-else/>
    <LayoutArticle :class="isMobile ? 'mobile-view' : 'py-10'">
      <div class="max-w-prose mx-auto" :class="isMobile ? 'text-xs p-3' : 'text-lg'">
        <h1 class="mt-2 block text-center leading-8 font-extrabold tracking-tight text-gray-900"  :class="isMobile ? 'text-3xl' : 'text-xl'">
          {{ title }}
        </h1>
        <article id="62c7efb1-57c9-4cef-9e24-ccc1f2197b9a" class="page sans">
          <div class="page-body">
            <p id="6965199d-7e90-49ec-b8bf-b3da01920942" class="">Chúng tôi hiểu rằng sự riêng tư và bảo mật thông tin cá nhân rất quan trọng đối với người dùng, Metric Ecom Việt cam kết nỗ lực tối đa bảo vệ sự riêng tư của Người dùng.</p>
            <p id="4a07f99b-2895-4b8d-9a59-40f199c81018" class="">Chính sách bảo mật mô tả cách thức Metric Ecom Việt thu thập và xử lý các thông tin cá nhân khi Người dùng sử dụng sản phẩm/dịch vụ của Metric Ecom Việt. Người dùng khi sử dụng sản phẩm/dịch vụ của Metric Ecom Việt, thì đồng thời hoàn toàn đồng ý với các nội dung trong Chính sách bảo mật này. Metric Ecom Việt có thể sửa đổi nội dung của chính sách bằng cách đăng một bản sửa đổi lên hệ thống, phiên bản sửa đổi có hiệu lực kể từ thời điểm đăng tải. Nếu Người dùng tiếp tục sử dụng Sản phẩm/Dịch vụ, có nghĩa là Người dùng chấp nhận và chắc chắn đồng ý tuân theo Điều khoản sử dụng mới nhất được cập nhật.</p>
            <h3 id="1d8f2762-c397-4ccf-b99f-0da0c200c7fa" class="">1. Thông tin Metric Ecom Việt cần Người dùng cung cấp</h3>
            <p id="8aea3dbc-b89a-4206-ba7b-9ee54e02ac59" class="">Để có thể sử dụng đầy đủ các tiện ích trên các sản phẩm của Metric Ecom Việt, Người dùng cần phải đăng ký thành viên và cung cấp các thông tin cá nhân của mình khi cần. Các thông tin của Người dùng cơ bản bao gồm (nhưng không giới hạn) như sau:</p>
            <ul id="b035f5c8-63a3-4ae0-b3d6-b86d29d857ba" class="bulleted-list">
              <li>Tên công ty, cửa hàng, đơn vị kinh doanh</li>
            </ul>
            <ul id="1cc4f09f-053c-432c-ae2e-df3f35b6c87f" class="bulleted-list">
              <li>Họ tên, tuổi, địa chỉ cư trú</li>
            </ul>
            <ul id="c80bba01-566f-43de-9133-b29d66fe1666" class="bulleted-list">
              <li>Email, số điện thoại di động, số điện thoại bàn</li>
            </ul>
            <ul id="3d441173-20f8-4f1a-b83b-98acea96bd8e" class="bulleted-list">
              <li>Địa chỉ IP (Internet Protocol), loại trình duyệt web (Browser), tốc độ đường truyền, số trang Người dùng Metric Ecom Việt xem, thời gian Người dùng viếng thăm, những địa chỉ mà Browser này truy xuất đến.</li>
            </ul>
            <p id="b1a6bef1-69e9-4ec1-b7c3-3e56d20a8825" class="">Toàn bộ thông tin người dùng cung cấp cho Metric Ecom Việt sẽ được lưu giữ trên hệ thống của Metric Ecom Việt. Khi cần Người sử dụng Metric Ecom Việt có thể hỏi về hoạt động thu thập, xử lý thông tin liên quan đến cá nhân mình về bảo mật thông tin tại địa chỉ:</p>
            <p id="82fc2b92-f052-455c-a378-3a2da83a9994" class="">CÔNG TY CỔ PHẦN KHOA HỌC DỮ LIỆU</p>
            <p id="97b8917f-a9a7-4518-b1de-aafe902bda5c" class="">Địa chỉ trụ sở: Tầng 6, Tòa nhà AZ Lâm Viên, 107A Nguyễn Phong Sắc, P. Dịch Vọng, Q. Cầu Giấy, Tp. Hà Nội, Việt Nam.</p>
            <p id="3dd0d6e3-22ed-4bf0-a565-699c41da74e6" class="">Số điện thoại: +(84)-989-091-287</p>
            <h3 id="1e4114b4-3e53-48ac-b0f3-48acb66a39f5" class="">2. Metric Ecom Việt bảo vệ và lưu giữ thông tin cá nhân của Người dùng như thế nào?</h3>
            <p id="259334de-7908-4a57-bf01-7fa82141a057" class="">Metric Ecom Việt lưu giữ và xử lý thông tin cá nhân của Người dùng trên các máy chủ đặt tại Việt Nam, dữ liệu được bảo vệ bằng các biện pháp bảo vệ vật lý, điện tử bao gồm: tường lửa, mã hóa dữ liệu và thủ tục áp dụng theo quy định của luật bảo mật thông tin. Metric Ecom Việt chỉ cho phép truy cập thông tin cá nhân đối với những nhân viên cần nó để hoàn thành trách nhiệm công việc của họ trong hệ thống.</p>
            <p id="738b8e67-afc2-4094-9db5-3e405cc3d8ab" class="">Toàn bộ dữ liệu của khách hàng vẫn sẽ được lưu trữ trên hệ thống Metric Ecom Việt trong thời hạn 06 tháng kể từ khi chấm dứt hợp đồng cung ứng phần mềm hoặc kể từ khi khách hàng ngưng sử dụng sản phẩm/dịch vụ của Metric Ecom Việt. Hết 6 tháng, dữ liệu của khách hàng sẽ không còn được lưu trữ trên hệ thống.</p>
            <h3 id="0409c15f-c609-469e-be33-349a9e9eea2b" class="">3. Thông tin của Người dùng sẽ được lưu giữ tại hệ thống của Metric Ecom Việt khi nào?</h3>
            <p id="7335eb60-c6ae-46b4-b27b-d6d3263042ce" class="">Thông tin của Người dùng sẽ được lưu lại trên hệ thống của Metric Ecom Việt trong các trường hợp thường gặp sau:</p>
            <p id="f506e89b-2ef0-4066-9e01-094355c48ca6" class="">(a) Khi Người dùng đăng ký và/hoặc sử dụng các Sản phẩm/Dịch vụ của Metric Ecom Việt, hoặc mở một tài khoản với Metric Ecom Việt.</p>
            <p id="0e4a3624-5741-4043-ade6-e0e32e4e78e8" class="">(b) Khi Người dùng nộp bất cứ biểu mẫu nào bao gồm nhưng không giới hạn ở các mẫu đơn hoặc các mẫu khác liên quan đến các sản phẩm và dịch vụ của Metric Ecom Việt, kể cả trực tuyến hay bằng các hình thức tài liệu vật lý.</p>
            <p id="f0013acd-3d42-424b-b7e5-a378cfccaf05" class="">(c) Khi Người dùng ký kết bất kỳ thỏa thuận nào hoặc cung cấp bất kỳ tài liệu hoặc thông tin khác liên quan đến sự tương tác của bạn với Metric Ecom Việt, hoặc khi bạn sử dụng sản phẩm và dịch vụ của Metric Ecom Việt.</p>
            <p id="be3dc745-16db-4957-b772-8cd8698c5d32" class="">(d) Khi Người dùng tương tác với Metric Ecom Việt, chẳng hạn như qua điện thoại, (có thể được ghi âm), thư, fax, gặp trực tiếp, mạng xã hội và email.</p>
            <p id="15a6875f-3743-4763-a991-86869043ac02" class="">(e) Khi Người dùng sử dụng các dịch vụ điện tử hoặc tương tác với Metric Ecom Việt thông qua Nền tảng của hoặc sử dụng dịch vụ trên Nền tảng của Metric Ecom Việt. Điều này bao gồm, nhưng không giới hạn thông qua cookie mà Metric Ecom Việt có thể triển khai khi Người dùng tương tác với ứng dụng hoặc Nền tảng của Metric Ecom Việt.</p>
            <p id="7ae6ba98-b91e-4707-a3dd-9e508eff5852" class="">(f) Khi Người dùng thực hiện giao dịch thông qua Nền tảng hoặc Các Dịch vụ của Metric Ecom Việt;</p>
            <p id="fd3e69fd-1370-49ce-a12b-9f5c80c707dc" class="">(g) Khi Người dùng gửi phản hồi hoặc khiếu nại tới Metric Ecom Việt;</p>
            <p id="d81231bc-31bf-4a38-93b0-67a670b2cc80" class="">(h) Khi Người dùng cung cấp dữ liệu cá nhân của mình cho Metric Ecom Việt vì bất kỳ lý do nào;</p>
            <p id="bcdd0f8f-3dce-479b-a21e-9c99b8de376d" class="">(i) Các trường hợp khác mà Metric Ecom Việt thấy cần thiết phù hợp với quy định của pháp luật.</p>
            <h3 id="9873aeb7-8434-4344-b2e5-fac52bab7412" class="">4. Thông tin của Người dùng được lưu giữ để làm gì?</h3>
            <p id="0fa630aa-5c01-4c3f-9e45-ed80032c279f" class="">Mục tiêu của Metric Ecom Việt là mang lại cho doanh nghiệp, người dùng một hạ tầng sản phẩm/dịch vụ phục vụ nghiên cứu, quảng bá và phát triển thương hiệu cũng như mở rộng kinh doanh trên Internet một cách hiệu quả, tối ưu về chi phí và thời gian. Vì vậy, Metric Ecom Việt lưu giữ và sử dụng thông tin cá nhân của Người dùng nhằm:</p>
            <ul id="226d846e-2832-4316-b961-63e360a64bbf" class="bulleted-list">
              <li>Cung cấp thông tin, các dịch vụ và sự hỗ trợ theo yêu cầu của Người dùng</li>
            </ul>
            <ul id="b38c82a7-3cb4-44ed-b7d2-f5c338bf6c9e" class="bulleted-list">
              <li>Gửi email thông báo các chương trình, sự kiện tiêu biểu của Metric Ecom Việt</li>
            </ul>
            <ul id="09e163c1-714e-45dc-904c-5d77afb3abd5" class="bulleted-list">
              <li>Giải quyết các tranh chấp, các vấn đề phát sinh liên quan đến việc sử dụng Sản phẩm/Dịch vụ của Metric Ecom Việt</li>
            </ul>
            <ul id="46f6efdd-4d0f-432e-ac5e-dbc3d8f685e3" class="bulleted-list">
              <li>Ngăn chặn các hoạt động phạm pháp hoặc bị cấm được nêu trong Quy định sử dụng</li>
            </ul>
            <ul id="5cc9581d-51b9-4c04-8425-e93e6bf7f950" class="bulleted-list">
              <li>Đo lường và cải thiện các dịch vụ của Metric Ecom Việt</li>
            </ul>
            <ul id="772f540b-1ad1-4e9a-bd5e-33c0979e8ffe" class="bulleted-list">
              <li>So sánh, đối chiếu tính chính xác của thông tin mà Người dùng Metric Ecom Việt cung cấp với bên thứ ba</li>
            </ul>
            <ul id="7b30cc22-b495-4aeb-bff5-0f87d044fb0f" class="bulleted-list">
              <li>Các mục đích khác được phép theo quy định của pháp luật và Metric Ecom Việt sẽ có thông báo cho Người dùng trong từng trường hợp cụ thể.</li>
            </ul>
            <p id="27d02d54-ba23-4e1d-ac62-8f6b1eb9a157" class="">Khi sử dụng Sản phẩm/Dịch vụ của Metric Ecom Việt, Người dùng hiểu và chấp nhận rằng Metric Ecom Việt có quyền truy cập vào dữ liệu của Người dùng và dữ liệu trên các thiết bị của Người dùng như: Đọc và ghi vào danh bạ điện thoại, lấy vị trí hiện tại của Người dùng, ghi dữ liệu của Metric Ecom Việt lên thẻ nhớ, truy cập vào Internet từ thiết bị của Người dùng.</p>
            <p id="cd542e2a-8805-405d-be09-dbc336f72b89" class="">Tất cả các truy cập này chỉ được Metric Ecom Việt thực hiện khi có sự đồng ý của Người dùng. Khi Người dùng đã cấp quyền cho Metric Ecom Việt, điều này được tất cả các bên thừa nhận rằng Người dùng sẽ không có bất kỳ khiếu nại nào đối với việc truy cập này;</p>
            <p id="24eaccf2-f9a1-400d-923c-dcb315c763d4" class="">Metric Ecom Việt cam kết sẽ tuyệt đối giữ kín mọi thông tin của Người dùng khi sử dụng Sản phẩm/Dịch vụ của Metric Ecom Việt và sẽ không tiết lộ cho bên thứ ba trừ khi có sự chấp thuận của Người dùng hoặc yêu cầu từ các cơ quan nhà nước có thẩm quyền hoặc pháp luật cho phép. Metric Ecom Việt chỉ thay đổi thông tin của Người dùng trên Sản phẩm/Dịch vụ khi có sự đồng ý hoặc xác nhận yêu cầu của Người dùng.</p>
            <h3 id="32830ac6-3247-40ab-a796-3ea33376e6c3" class="">5. Người dùng cần làm gì để bảo mật thông tin?</h3>
            <p id="77ef80d3-43e9-4b49-9540-378fcf1568c5" class="">Sau khi hoàn thành việc bàn giao sản phẩm, dịch vụ, Người dùng là đơn vị duy nhất sở hữu và có trách nhiệm quản lý một cách an toàn các thông tin nhận biết, tài khoản quản trị, mật khẩu, nội dung website hay những thông tin khác liên quan đến tài khoản, website của mình. Người dùng có thể đăng nhập vào tài khoản của mình để chỉnh sửa thông tin, có quyền yêu cầu Metric Ecom Việt thực hiện việc kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông tin cá nhân của mình đã đăng ký với Metric Ecom Việt. Metric Ecom Việt sẽ kiểm tra, cập nhật, điều chỉnh, hủy bỏ thông tin cá nhân của Người dùng khi có yêu cầu hoặc cung cấp cho chủ thể thông tin công cụ để tự kiểm tra, cập nhật, điều chỉnh thông tin cá nhân của mình. Metric Ecom Việt chỉ hỗ trợ Người dùng thông qua tài khoản được Người dùng chủ động cung cấp cho Metric Ecom Việt. Người dùng ngay lập tức thông báo cho Metric Ecom Việt khi phát hiện các hình thức truy cập trái phép bằng tài khoản của mình hoặc các sơ hở về bảo mật, bao gồm việc mất mát, bị đánh cắp hoặc bị lộ các thông tin về mật khẩu và các thông tin bảo mật khác để được hỗ trợ.</p>
            <p id="98254b7d-0f2f-44f1-bd0b-ea16d0bae075" class="">Người dùng phải tự chịu trách nhiệm về nội dung các email gửi đi từ hộp thư trong tài khoản và các nội dung trên website của mình. Khi Người dùng cung cấp email và đăng ký dùng thử đồng nghĩa với việc Người dùng đồng ý nhận email marketing từ Metric Ecom Việt.</p>
            <p id="463891a8-b334-4122-ad66-0f48f3d5050b" class="">Người dùng sử dụng Sản phẩm/Dịch vụ của Metric Ecom Việt phải có trách nhiệm bảo quản thiết bị có sử dụng phần mềm, cài đặt Sản phẩm/Dịch vụ của Metric Ecom Việt trong tầm kiểm soát và an toàn, lưu giữ thông tin truy cập vào Cửa hàng để tránh trường hợp tài khoản bị đánh cắp và/ hoặc bị lạm dụng với mục đích không an toàn cho cả Người dùng và Metric Ecom Việt. Metric Ecom Việt khuyến cáo Người dùng nên đổi lại mật khẩu sớm sau khi cung cấp mật khẩu (nếu có) cho các bên liên quan nhằm mục đích hỗ trợ sử dụng.</p>
            <p id="d79c8983-c97e-4278-8066-9da501503b3e" class="">Metric Ecom Việt sẽ không chịu trách nhiệm đối với sự xâm nhập trái phép của bên thứ ba vào Sản phẩm/Dịch vụ của Metric Ecom Việt do sự bất cẩn từ phía Người dùng.</p>
            <h3 id="05304516-a012-4b77-93ee-5dd3d5b00733" class="">6. Những người hoặc tổ chức được phép tiếp cận thông tin Người dùng.</h3>
            <p id="f2c405be-ef66-4562-a8d4-958e08316fe5" class="">Metric Ecom Việt cam kết không cung cấp thông tin cá nhân của Người dùng cho bất kỳ bên thứ ba nào. Tuy nhiên, các cá nhân, tổ chức khác có thể được tiếp cận thông tin của Người dùng trong một số trường hợp dưới đây:</p>
            <ul id="282d6eab-7622-4f4d-9355-3f52968b51c1" class="bulleted-list">
              <li>Thông tin đó Người dùng đã công khai</li>
            </ul>
            <ul id="79038913-818d-4dd7-b814-28b927a40855" class="bulleted-list">
              <li>Metric Ecom Việt được Người dùng đồng ý tiết lộ những thông tin này</li>
            </ul>
            <ul id="ab92a309-f337-4d7f-a603-a0ab10d14b93" class="bulleted-list">
              <li>Bên thứ ba mà Người dùng ủy quyền hoặc cho phép có yêu cầu Metric Ecom Việt cung cấp thông tin cá nhân của Người dùng, việc ủy quyền, cho phép phải được thể hiện bằng văn bản có công chứng, chứng thực</li>
            </ul>
            <ul id="0838c839-2c75-468a-adb6-d41277e76ea1" class="bulleted-list">
              <li>Theo yêu cầu pháp lý hay từ một cơ quan nhà nước hoặc nếu Metric Ecom Việt tin rằng hành động đó là cần thiết nhằm tuân theo các yêu cầu pháp lý hoặc chiếu theo luật pháp</li>
            </ul>
            <ul id="ada6a59d-cd68-4ab2-ac8f-2c4ecef69d0d" class="bulleted-list">
              <li>Bảo vệ quyền, lợi ích, tài sản, sự an toàn của một ai khác trên cơ sở cân bằng lợi ích của tất cả các bên</li>
            </ul>
            <ul id="73ba73c5-d585-4e47-943f-65593e057e2d" class="bulleted-list">
              <li>Cho các bên thứ ba khác mà Metric Ecom Việt có liên doanh, liên kết để cung cấp các dịch vụ trên Metric Ecom Việt hoặc các dịch vụ mới có liên quan đến Metric Ecom Việt mà Người dùng cũng đang sử dụng những dịch vụ liên kết đó trên các Sản phẩm/Dịch vụ của Metric Ecom Việt.</li>
            </ul>
            <p id="20c0696d-0134-4ba2-adef-f05eed005fe6" class="">Ngoài những trường hợp nêu trên nhưng không giới hạn, thông tin cá nhân của Người dùng luôn được bảo mật trước các bên thứ ba nào trừ khi Metric Ecom Việt hoàn toàn tin rằng, sự công bố này là cần thiết nhằm ngăn chặn những thiệt hại vật chất hoặc tài chính do các yếu tố có dấu hiệu phạm pháp có thể gây ra.</p>
            <h3 id="3bca4e93-e0ee-4672-a611-66c72b6c6655" class="">7. Quản lý thông tin trên website</h3>
            <p id="90e7307c-4574-4f80-ab25-b00c3b9f6b66" class="">Người dùng có thể truy cập, sửa chữa và cập nhật thông tin trên website của mình bằng cách đăng nhập và sử dụng các tính năng quản trị. Metric Ecom Việt hoàn toàn không chịu trách nhiệm liên quan đến tính chính xác và hợp pháp của thông tin Người dùng đưa lên website.</p>
            <h3 id="f429a06c-01ba-44ba-bca8-d781f6e52a37" class="">8. Sử dụng Cookies</h3>
            <p id="b1f2b916-9d88-41d2-b23c-ff2a48f0d0d5" class="">Cookie là một file dữ liệu được đặt trên đĩa cứng của Người dùng bởi máy chủ của Metric Ecom Việt. Metric Ecom Việt sử dụng Cookie để cá nhân hóa và nâng cao hiệu quả sử dụng thời gian trực tuyến của Người dùng.</p>
            <p id="737f9e5b-c803-4606-baf6-a3713f366e66" class="">Cookie sẽ giúp Metric Ecom Việt nhận ra Người dùng nếu Người dùng truy cập vào nhiều trang trên trang web của Metric Ecom Việt trong cùng một phiên, do đó Metric Ecom Việt không cần phải hỏi mật khẩu của Người dùng trên mỗi trang. Sau khi Người dùng đăng xuất hoặc đóng trình duyệt, cookie này sẽ hết hạn và không còn có bất kỳ tác dụng nào. Người dùng có thể chấp nhận hoặc từ chối dùng cookie. Hầu hết những Browser tự động chấp nhận cookie, nhưng Người dùng có thể thay đổi cài đặt để từ chối tất cả những cookie nếu Người dùng thích. Tuy nhiên, nếu Người dùng chọn từ chối cookie, điều đó có thể gây cản trở và ảnh hưởng đến một số dịch vụ và tính năng phụ thuộc vào cookie tại Metric Ecom Việt.</p>
            <h3 id="e83a745a-89f9-4646-b55f-47e37fd014fb" class="">9. Giải quyết khiếu nại</h3>
            <p id="ae050bf9-d5f1-4b27-b857-0e62d1c06cec" class="">Mọi trường hợp đóng góp ý kiến, khiếu nại, Người dùng vui lòng nào gửi về Metric Ecom Việt theo các phương thức sau gửi email tới địa chỉ: metric@ecomviet.vn, hoặc gọi đến hotline của bộ phận chăm sóc khách hàng: 033.806.2221, hoặc liên hệ trực tiếp:</p>
            <p id="986a6e74-8399-45d8-80e6-84da20be5e85" class="">CÔNG TY CỔ PHẦN KHOA HỌC DỮ LIỆU</p>
            <p id="d9fe2f86-2804-4f04-808a-f185ab3e1e53" class="">Địa chỉ trụ sở: Tầng 6, Tòa nhà AZ Lâm Viên, 107A Nguyễn Phong Sắc, P. Dịch Vọng, Q. Cầu Giấy, Tp. Hà Nội, Việt Nam.</p>
            <p id="c6667352-a25a-4e7e-8b35-3a7bcbd97c17" class="">Số điện thoại: +(84)-989-091-287</p>
            <p id="4e8e66bd-197a-443c-a253-7ad06e6c3797" class="">Tùy thuộc vào tính chất phức tạp của nội dung khiếu nại, Metric Ecom Việt sẽ có thời hạn xử lý tương ứng. Kết quả giải quyết khiếu nại sẽ được thông báo tới Người dùng, trường hợp cần thiết, Metric Ecom Việt có thể mời người khiếu nại tới làm việc trực tiếp. Metric Ecom Việt sẽ nỗ lực để luôn giải quyết các khiếu nại của Người dùng trong thời gian sớm nhất và trên tinh thần thương lượng, hòa giải, tôn trọng, hai bên cùng có lợi.</p>
            <h3 id="5f73b24f-4dd3-49aa-8f28-6ab964bb1f1a" class="">10. Các chính sách liên quan</h3>
            <p id="0b410b94-c719-40d9-89f7-c9a7dfb5a877" class="">Chính sách này là một phần và song hành với <a href="https://market.beecost.vn/terms">Điều khoản sử dụng</a> của Metric Ecom Việt mà Người dùng đồng ý tuân thủ khi dùng Sản phẩm/Dịch vụ của Metric Ecom Việt.</p>
            <p id="a0b482ed-3918-4e7a-b538-cc72019f5c47" class="">Nếu Người dùng sử dụng các Sản phẩm/Dịch vụ khác trong hệ sinh thái của BeeCost, chính sách này sẽ bao gồm cả <a href="https://beecost.vn/privacy">chính sách bảo mật của BeeCost</a> cũng như <a href="https://beecost.vn/term">điều khoản sử dụng của BeeCost</a>.</p>
          </div>
        </article>
      </div>
    </LayoutArticle>
    <BeeFooter/>
  </div>
</template>
<script>

import LayoutArticle from "@/layout/LayoutArticle";
import BeeHeader from "@/components/partial/header/BeeHeader";
import BeeFooter from "@/components/partial/footer/BeeFooter";
import {MESSENGER_ID} from "@/constant/general/GeneralConstant";
import MobileHeader from "@/components/partial/header/MobileHeader";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "PrivacyView",
  components: {
    BeeHeader,
    BeeFooter,
    LayoutArticle,
    MobileHeader
  },
  mixins: [
    mixinStoreGetterGlobal
  ],
  data() {
    let title = 'Chính sách bảo mật'
    return {
      title,
      MESSENGER_ID
    }
  },
  head() {
    return {
      title: this.title,
    }
  }
}

</script>

<style lang="scss" scoped>
.p-content {
  @apply text-lg text-gray-600 leading-8;
}
</style>
